import React from 'react';
import Layout from '../layouts/index';
import CourseImage from '../images/featuredImages/demoImage.png';
import Link from 'gatsby-link';
import PromoBanner from '../components/PromoBanner';

class CourseDetails extends React.Component {
	render() {
		return (
			<Layout>
				{/* Course info */}
				<PromoBanner />
				<div className="contentContainer container-fluid bg-white">
					<div className="container margin-from-header pt-6 pb-6">
						<div className="row d-flex justify-content-md-center reverse-flex-mobile">
							<div className="col-sm-12 col-md-5">
								<h1>Easy Vietnamese Conversation Phrases</h1>
								<p>
									Learn daily Vietnamese Conversation Phrases by Topic with
									downloadable PDF Transcript and Audio for beginners, designed
									with practical questions and answers
								</p>
								<div className="row pl-1 mt-3">
									<h1 className="text-success mb-0 ml-md-2">FREE</h1>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<img
									src={CourseImage}
									className="img-fluid float-right rounded"
									alt="Easy Vietnamese conversation"
								></img>
							</div>
						</div>
					</div>
				</div>

				{/* Course contents */}
				<div className="container margin-from-header pb-6">
					<div className="row justify-content-md-center">
						<div className="col-sm-12 col-md-8">
							<h2>What will you achieve?</h2>
							<ul>
								<li>
									Learn Real-life Vietnamese conversation by topics for beginner
									level
								</li>
								<li>
									Improve your listening with Audio MP3s of all the conversation
									in natural speed
								</li>
								<li>
									Practice along with a native speaker to improve your
									pronunciation
								</li>
								<li>
									Check your understanding and learn new vocabulary with the PDF
									Transcript
								</li>
							</ul>

							<h2 className="pt-6">Table of Contents</h2>
							<ol className="list-group">
								<li className="list-group-item">Lesson 1: Self-introduction</li>
								<li className="list-group-item">Lesson 2: Talking About Job</li>
								<li className="list-group-item">
									Lesson 3: Talking About Family
								</li>
								<li className="list-group-item">Lesson 4: Eating Out</li>
								<li className="list-group-item">
									Lesson 5: Talking About Movies
								</li>
							</ol>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default CourseDetails;
